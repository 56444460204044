import React, { useEffect, useState } from 'react';
import { API_URL_MANIFESTS, get } from '../../App/Api';
import { Table, Spin, Alert, Button, message, Form, Space, Select } from 'antd';
import { ManifestForm } from './ManifestForm';
import { AccordeonFilters } from './Accordeon/AccordeonFilters';
import { useTranslation } from 'react-i18next';
import { Paginator } from '../../Paginator';
import MainLayout from '../../Layout/MainLayout';

export function Manifests() {
	const { t, i18n } = useTranslation();

	const [manifests, setManifests] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [perPage, setPerPage] = useState(10);
	const [manifestPage, setManifestPage] = useState(1);
	const [manifestIds, setManifestIds] = useState([]);
	const [clientIds, setClientIds] = useState([]);
	const [masterAirWayBill, setMasterAirWayBill] = useState('');
	const [hasMorePages, setHasMorePages] = useState(true);

	useEffect(() => {
		fetchData();
	}, [perPage, manifestPage, i18n.language]);

	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await get(API_URL_MANIFESTS, {
				manifestIds,
				clientIds,
				masterAirWayBills: masterAirWayBill ? masterAirWayBill : undefined,
				perPage: perPage,
				page: manifestPage,
			});
			setManifests(response.data);

			if (response.data?.length < perPage) {
				setHasMorePages(false);
			} else {
				setHasMorePages(true);
			}
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleAddManifest = () => {
		setManifests(null);
		setIsEditing(true);
	};

	const handleEdit = (manifest) => {
		setIsEditing(true);
		setManifests(manifest);
	};

	const closeEditForm = () => {
		setError();
		setIsEditing(null);
		fetchData();
	};

	const loadMoreManifest = () => {
		if (hasMorePages && !loading) {
			setLoading(true);
			setManifestPage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (manifestPage > 1 && !loading) {
			setLoading(true);
			setManifestPage((prevPage) => prevPage - 1);
		}
	};

	const handleSearch = () => {
		setManifestPage(1);
		fetchData();
	};

	const columns = [
		{
			title: t('manifests.manifestId.text'),
			dataIndex: 'manifestId',
			key: 'manifestId',
		},
		{
			title: t('manifests.masterAirWayBill.text'),
			dataIndex: 'masterAirWayBill',
			key: 'masterAirWayBill',
		},
		{
			title: t('manifests.flightDate.text'),
			dataIndex: 'flightDate',
			key: 'flightDate',
		},
		{
			title: t('actions'),
			key: 'edit',
			render: (manifest, record) => (
				<Space>
					<Button onClick={() => handleEdit(record, manifest)}>
						{t('edit')}
					</Button>
				</Space>
			),
		},
	];

	const filterProps = {
		setClientIds,
		setManifestIds,
		setMasterAirWayBill,
		handleSearch,
		setError,
		masterAirWayBill,
		clientIds,
	};

	return (
		<MainLayout>
			{!isEditing && <AccordeonFilters filterProps={filterProps} />}
			{error && (
				<Alert message="Error" description={error} type="error" showIcon />
			)}

			{!isEditing && (
				<>
					{loading ? (
						<Spin size="large" />
					) : error ? (
						<Alert message="Error" description={error} type="error" showIcon />
					) : (
						<>
							<div className="manifest-footer">
								<Button type="primary" onClick={handleAddManifest}>
									{t('manifests.add')}
								</Button>
								<Button
									onClick={handlePreviousPage}
									disabled={manifestPage === 1}
								>
									{t('previousPage')}
								</Button>
								<Button
									onClick={loadMoreManifest}
									disabled={loading}
									className="load-more-button"
								>
									{t('nextPage')}
								</Button>
								<Paginator perPage={perPage} setPerPage={setPerPage} />
							</div>
							<div className="manifest-table">
								<Table
									dataSource={manifests}
									columns={columns}
									rowKey="manifestId"
									pagination={false}
								/>
							</div>
						</>
					)}
				</>
			)}

			{isEditing && (
				<ManifestForm
					manifestId={manifests?.manifestId}
					onClose={closeEditForm}
					setError={setError}
				/>
			)}
		</MainLayout>
	);
}
