import React, { useState, useEffect } from 'react';
import {
	API_URL_MANIFESTS,
	API_URL_MANIFESTS_ID,
	API_URL_MANIFESTS_UPLOAD,
	get,
	patch,
	post,
} from '../../../App/Api';
import {
	Alert,
	Button,
	Card,
	Col,
	DatePicker,
	Form,
	Input,
	InputNumber,
	message,
	Row,
	Upload,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ClientFilter } from '../../../Client/Filter';
import moment from 'moment/moment';
import { UploadOutlined } from '@ant-design/icons';

export function ManifestForm({ manifestId = undefined, onClose }) {
	const { t, i18n } = useTranslation();

	const [manifest, setManifest] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [form] = Form.useForm();

	useEffect(() => {
		fetch();
	}, []);

	const fetch = async () => {
		try {
			if (manifestId) {
				const response = await get(API_URL_MANIFESTS + manifestId);
				const manifest = response.data;
				setManifest(manifest);
			} else {
				setManifest({});
			}
		} catch (error) {
			setError(error?.response?.data?.message);
		} finally {
			setLoading(false);
		}
	};

	const setClientIds = (clientId) => {
		const newManifest = manifest;
		form.clientId = clientId;
		newManifest.clientId = clientId;
		setManifest(newManifest);
		form.setFieldsValue({ clientId });
	};

	const handleSubmit = async (form) => {
		setError();

		try {
			form.flightDate = form.flightDate?.format('YYYY-MM-DD HH:mm:ss');
			form.estimatedArrivalDate = form.estimatedArrivalDate?.format(
				'YYYY-MM-DD HH:mm:ss'
			);
			form.realArrivalDate = form.realArrivalDate?.format(
				'YYYY-MM-DD HH:mm:ss'
			);

			form.manifestDate = form.manifestDate?.format('YYYY-MM-DD HH:mm:ss');

			if (form.manifest?.[0]?.originFileObj) {
				const formData = new FormData();
				formData.append('manifest', form.manifest?.[0].originFileObj);
				delete form.manifest;
				Object.keys(form).forEach((key) => {
					if (form[key]) {
						formData.append(key, form[key]);
					}
				});

				await post(`${API_URL_MANIFESTS_UPLOAD}`, formData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				});
			} else {
				await patch(
					API_URL_MANIFESTS_ID.replace('{{manifestId}}', manifestId),
					form
				);
			}

			message.open({
				type: 'success',
				content: t('manifests.success.text'),
			});

			onClose();
		} catch (error) {
			setError(error?.response?.data?.message);
		}
	};

	if (loading) {
		return <p>Loading manifest data...</p>;
	}

	return (
		<div>
			{error && <Alert message={error} type="warning" showIcon />}
			<Row justify="center" align="middle">
				<Col
					xs={{ flex: '100%' }}
					sm={{ flex: '90%' }}
					md={{ flex: '80%' }}
					lg={{ flex: '60%' }}
					xl={{ flex: '50%' }}
				>
					<Card>
						<Form
							form={form}
							labelCol={{
								span: 8,
							}}
							wrapperCol={{
								span: 16,
							}}
							initialValues={{
								clientId: manifest?.clientId,
								masterAirWayBill: manifest?.masterAirWayBill,
								weight: manifest?.weight,
								flightDate: manifest?.flightDate
									? moment(manifest.flightDate)
									: undefined,
								airLine: manifest?.airLine,
								flightNumber: manifest?.flightNumber,
								airportDeparture: manifest?.airportDeparture,
								airportDestination: manifest?.airportDestination,
								estimatedArrivalDate: manifest?.estimatedArrivalDate
									? moment(manifest.estimatedArrivalDate)
									: null,
								realArrivalDate: manifest?.realArrivalDate
									? moment(manifest.realArrivalDate)
									: null,
								manifestDate: manifest?.manifestDate
									? moment(manifest.manifestDate)
									: null,
							}}
							onFinish={handleSubmit}
							autoComplete="off"
						>
							<Form.Item
								label={t('manifests.clientId.text')}
								name="clientId"
								rules={[
									{
										required: true,
										message: t('manifests.clientId.required'),
									},
								]}
							>
								<ClientFilter
									mode={null}
									setClientIds={setClientIds}
									clientIds={[manifest?.clientId]}
								/>
							</Form.Item>

							<Form.Item
								label={t('manifests.masterAirWayBill.text')}
								name="masterAirWayBill"
								rules={[
									{
										required: true,
										message: t('manifests.masterAirWayBill.required'),
									},
								]}
							>
								<Input />
							</Form.Item>

							<Form.Item
								label={t('manifests.weight.text')}
								name="weight"
								rules={[
									{
										required: true,
										message: t('manifests.weight.required'),
									},
								]}
							>
								<InputNumber
									style={{
										width: '100%',
									}}
									suffix="kg"
									formatter={(value) =>
										`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
									}
									parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
								/>
							</Form.Item>

							<Form.Item label={t('manifests.airLine.text')} name="airLine">
								<Input />
							</Form.Item>

							<Form.Item
								label={t('manifests.airportDeparture.text')}
								name="airportDeparture"
							>
								<Input />
							</Form.Item>

							<Form.Item
								label={t('manifests.airportDestination.text')}
								name="airportDestination"
							>
								<Input />
							</Form.Item>

							<Form.Item
								label={t('manifests.flightNumber.text')}
								name="flightNumber"
							>
								<Input />
							</Form.Item>

							<Form.Item
								label={t('manifests.flightDate.text')}
								name="flightDate"
								rules={[
									{
										required: true,
										message: t('manifests.estimatedArrivalDate.required'),
									},
								]}
							>
								<DatePicker showTime />
							</Form.Item>

							<Form.Item
								label={t('manifests.manifestDate.text')}
								name="manifestDate"
								rules={[
									{
										required: true,
										message: t('manifests.estimatedArrivalDate.required'),
									},
								]}
							>
								<DatePicker showTime />
							</Form.Item>

							<Form.Item
								label={t('manifests.estimatedArrivalDate.text')}
								name="estimatedArrivalDate"
								rules={[
									{
										required: true,
										message: t('manifests.estimatedArrivalDate.required'),
									},
								]}
							>
								<DatePicker showTime />
							</Form.Item>

							<Form.Item
								label={t('manifests.realArrivalDate.text')}
								name="realArrivalDate"
							>
								<DatePicker showTime />
							</Form.Item>

							<Form.Item
								label={t('manifests.manifestFile.text')}
								name="manifest"
								getValueFromEvent={(e) =>
									Array.isArray(e) ? e : e && e.fileList
								}
								rules={[
									{
										required: !manifestId,
										message: t('manifests.manifestFile.required'),
									},
								]}
							>
								<Upload
									name="manifest"
									beforeUpload={(info) => false}
									listType="text"
									maxCount={1}
								>
									<Button icon={<UploadOutlined />}>Click to Upload</Button>
								</Upload>
							</Form.Item>

							<Form.Item style={{ textAlign: 'center' }} wrapperCol={24}>
								<Button type="primary" danger onClick={onClose}>
									{t('manifests.cancel')}
								</Button>
								<Button type="primary" htmlType="submit">
									{t('manifests.save')}
								</Button>
							</Form.Item>
						</Form>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
