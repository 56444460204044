import React from 'react';
import { Button, Col, Collapse, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FleetStateFilter } from '../../../Fleet/State';
import { FleetFilter } from '../../../Fleet/Filter';
import { InputOrderSearch } from '../../Orders/SearchReference';
import { ClientFilter } from '../../../Client/Filter';
import { EmployeeFilter } from '../../../Employee/Filter';
import { FleetCityFilter } from '../../../Fleet/City';

export function FollowingAccordeonFilters({ filterProps }) {
	const { t } = useTranslation();
	const items = [
		{
			key: 'mainAccordion',
			label: t('filters.filters'),
			children: (
				<>
					<Row gutter={[16, 16]}>
						<Col span={8}>
							<p style={{ fontSize: '13px', margin: '5px 0' }}>
								{t('filters.fleets')}
							</p>
							<FleetFilter {...filterProps} />
						</Col>
						<Col span={8}>
							<p style={{ fontSize: '13px', margin: '5px 0' }}>
								{t('filters.states')}
							</p>
							<FleetStateFilter {...filterProps} />
						</Col>
						<Col span={8}>
							<p style={{ fontSize: '13px', margin: '5px 0' }}>
								{t('filters.cities')}
							</p>
							<FleetCityFilter {...filterProps} />
						</Col>
						<Col span={8}>
							<p style={{ fontSize: '13px', margin: '5px 0' }}>
								{t('filters.employees')}
							</p>
							<EmployeeFilter {...filterProps} />
						</Col>
						<Col span={8}>
							<p style={{ fontSize: '13px', margin: '5px 0' }}>
								{t('filters.references')}
							</p>
							<InputOrderSearch {...filterProps} />
						</Col>
						<Col span={8}>
							<p style={{ fontSize: '13px', margin: '5px 0' }}>
								{t('filters.clients')}
							</p>
							<ClientFilter {...filterProps} />
						</Col>
					</Row>
					<Row justify="end" align="middle" style={{ marginTop: '20px' }}>
						<Col>
							<Button type="primary" onClick={filterProps.handleSearch}>
								{t('filters.search')}
							</Button>
						</Col>
					</Row>
				</>
			),
		},
	];
	return (
		<Collapse
			defaultActiveKey={[]}
			style={{
				backgroundColor: '#fff',
				borderRadius: '8px',
				marginBottom: '1rem',
				width: '100%',
				margin: '0 auto',
			}}
			items={items}
		/>
	);
}
