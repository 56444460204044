import React, { useEffect, useState } from 'react';
import { Button, Table, Spin, Checkbox, Flex } from 'antd';
import { API_URL_ORDERS, API_URL_PODS_PDF, get } from '../../App/Api';
import { AccordionFilters } from './Accordion';
import { useTranslation } from 'react-i18next';
import { getFromLocalStorage, saveToLocalStorage } from '../Login/storage';
import {
	FilePdfOutlined,
	DeleteOutlined,
	ExpandAltOutlined,
} from '@ant-design/icons';
import { Paginator } from '../../Paginator';
import MainLayout from '../../Layout/MainLayout';
import { Link } from 'react-router-dom';

export function Orders() {
	const { t, i18n } = useTranslation();

	const [checkedOrders, setCheckedOrders] = useState(
		getFromLocalStorage('order.pdfs') || []
	);

	const [orders, setOrders] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [page, setPage] = useState(
		parseInt(getFromLocalStorage('order.page'), 10) || 1
	);

	// STATE FOR PETITION
	const [searchInput, setSearchInput] = useState(
		getFromLocalStorage('order.searchInput')
	);
	const [businessIds, setBusinessIds] = useState(
		getFromLocalStorage('order.businessIds')
	);
	const [clientIds, setClientIds] = useState(
		getFromLocalStorage('order.clientIds')
	);
	const [employeeIds, setEmployeeIds] = useState(
		getFromLocalStorage('order.employeeIds')
	);
	const [fleetIds, setFleetIds] = useState(
		getFromLocalStorage('order.fleetIds')
	);
	const [selectedStatusCodes, setSelectedStatusCodes] = useState(
		getFromLocalStorage('order.selectedStatusCodes')
	);
	const [perPage, setPerPage] = useState(
		getFromLocalStorage('order.perPage') || 10
	);

	const [hasMorePages, setHasMorePages] = useState(true);
	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const [columns, setColumns] = useState([]);

	const fetchData = async () => {
		setLoading(true);
		try {
			let url = `${API_URL_ORDERS}`;
			const response = await get(url, {
				perPage,
				page,
				references: searchInput ? searchInput : null,
				businessIds,
				clientIds,
				employeeIds,
				fleetIds,
				orderStatusCodes: selectedStatusCodes,
			});
			setOrders(response.data);
			setHasMorePages(response.data.length === perPage);
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
			setIsLoadingMore(false);
		}
	};

	const checkPdfs = (e) => {
		const orderId = e.target?.value;
		const checked = e.target?.checked;
		const position = checkedOrders.indexOf(orderId);

		let newCheckedOrders = [...checkedOrders];
		if (checked && position === -1) {
			newCheckedOrders.push(orderId);
		} else if (!checked && position >= 0) {
			newCheckedOrders.splice(position, 1);
		}
		e.target.checked = checked;
		saveToLocalStorage('order.pdfs', newCheckedOrders);
		setCheckedOrders(newCheckedOrders);
	};

	const handleSearch = () => {
		setPage(1);
		fetchData();
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};

	const handlePreviousPage = () => {
		let newPage = page;
		if (page > 1) {
			newPage--;
			setIsLoadingMore(true);
			setPage(newPage);
		}
	};

	const loadMoreOrders = () => {
		const newPage = page + 1;
		setIsLoadingMore(true);
		setPage(newPage);
	};

	const handleGoToStart = () => {
		if (page > 1) {
			setPage(1);
		}
	};

	const cleanOrderPdfs = () => {
		saveToLocalStorage('order.pdfs', []);
		setCheckedOrders([]);
	};

	useEffect(() => {
		setColumns([
			{
				title: t('orders.pdf'),
				key: 'pdfs',
				render: (order, record) => (
					<>
						{order.orderStatusParentCode === 'DLV' && (
							<Checkbox
								key={'checkbox' + order.orderId}
								value={order.orderId}
								checked={!!checkedOrders?.includes(order.orderId)}
								onChange={checkPdfs}
							/>
						)}
					</>
				),
			},
			{
				title: t('orders.Reference'),
				dataIndex: 'reference',
				key: 'reference',
			},
			{
				title: t('fleets.name'),
				dataIndex: 'fleetName',
				key: 'fleetName',
			},
			{
				title: t('employees.name'),
				dataIndex: 'employeeName',
				key: 'employeeName',
			},
			{
				title: t('orders.status'),
				dataIndex: 'status',
				key: 'status',
				align: 'center',
				render: (text, record) => (
					<>
						{record?.orderStatus === record?.orderStatusParent ? (
							<>{record?.orderStatusParent}</>
						) : (
							<>
								{record?.orderStatusParent}-{record?.orderStatus}
							</>
						)}
					</>
				),
			},
			{
				title: t('orders.currentAddress'),
				dataIndex: 'currentAddress',
				key: 'currentAddress',
			},
			{
				title: t('orders.postalCode'),
				dataIndex: 'postalCode',
				key: 'postalCode',
			},
			{
				title: t('orders.city'),
				dataIndex: 'cityName',
				key: 'cityName',
			},
			{
				title: t('orders.Actions'),
				key: 'actions',
				render: (order, record) => (
					<Link to={'/orders/' + order.orderId}>
						<ExpandAltOutlined title={t('orders.Ver Detalles')} />
					</Link>
				),
			},
		]);
	}, [checkedOrders, i18n.language]);

	useEffect(() => {
		saveToLocalStorage('order.page', page);
	}, [page]);

	useEffect(() => {
		saveToLocalStorage('order.perPage', perPage);
	}, [perPage]);

	useEffect(() => {
		saveToLocalStorage('order.searchInput', searchInput);
	}, [searchInput]);

	useEffect(() => {
		saveToLocalStorage('order.businessIds', businessIds);
	}, [businessIds]);

	useEffect(() => {
		saveToLocalStorage('order.clientIds', clientIds);
	}, [clientIds]);

	useEffect(() => {
		saveToLocalStorage('order.employeeIds', employeeIds);
	}, [employeeIds]);

	useEffect(() => {
		saveToLocalStorage('order.selectedStatusCodes', selectedStatusCodes);
	}, [selectedStatusCodes]);

	useEffect(() => {
		fetchData();
	}, [page, perPage]);

	const filterProps = {
		handleSearch,

		setClientIds,
		clientIds,

		setFleetIds,
		fleetIds,

		setEmployeeIds,
		employeeIds,

		setBusinessIds,
		businessIds,

		setSearchInput,
		searchInput,

		setSelectedStatusCodes,
		selectedStatusCodes,
	};

	const openPdfs = async () => {
		setLoading(true);
		const response = await get(
			API_URL_PODS_PDF,
			{ orderIds: checkedOrders.join(',') },
			{
				responseType: 'blob',
			}
		);
		setLoading(false);
		if (
			response.data &&
			response.headers?.['content-type'] === 'application/pdf'
		) {
			if (response?.data) {
				const file = new Blob([response.data], { type: 'application/pdf' });
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL, '_blank');
			}
		}
	};

	return (
		<MainLayout>
			<div className="orders-filters">
				<form onKeyDown={handleKeyPress}>
					<AccordionFilters filterProps={filterProps} />
				</form>
			</div>
			{loading && <Spin size="large" />}
			{error && (
				<p>
					{t('orders.Error')}: {error}
				</p>
			)}
			{!loading && (
				<>
					<div className="orders-footer">
						<Button
							onClick={handleGoToStart}
							disabled={page <= 1 || isLoadingMore}
						>
							{t('orders.Ir al inicio')}
						</Button>
						<Button
							onClick={handlePreviousPage}
							disabled={page <= 1 || isLoadingMore}
						>
							{t('previousPage')}
						</Button>
						<Button
							onClick={loadMoreOrders}
							disabled={!hasMorePages || isLoadingMore}
							loading={loading}
						>
							{t('nextPage')}
						</Button>
						<Paginator perPage={perPage} setPerPage={setPerPage} />
					</div>
					{checkedOrders?.length > 0 && (
						<Flex gap="small" align="center" wrap>
							<Button
								type="primary"
								icon={<FilePdfOutlined />}
								onClick={() => openPdfs()}
							></Button>

							<Button
								type="primary"
								icon={<DeleteOutlined />}
								onClick={() => cleanOrderPdfs()}
							></Button>
						</Flex>
					)}
					<div className="orders-table">
						<Table
							virtual
							scroll={{ y: 500, x: 800 }}
							pagination={false}
							dataSource={orders}
							columns={columns}
							rowKey="orderId"
						/>
					</div>

					<div className="orders-footer">
						<Button
							onClick={handleGoToStart}
							disabled={page <= 1 || isLoadingMore}
						>
							{t('orders.Ir al inicio')}
						</Button>
						<Button
							onClick={handlePreviousPage}
							disabled={page <= 1 || isLoadingMore}
						>
							{t('previousPage')}
						</Button>
						<Button
							onClick={loadMoreOrders}
							disabled={!hasMorePages || isLoadingMore}
							loading={loading}
						>
							{t('nextPage')}
						</Button>
						<Paginator perPage={perPage} setPerPage={setPerPage} />
					</div>
				</>
			)}
		</MainLayout>
	);
}
