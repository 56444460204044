import React from 'react';
import { Button, Col, Collapse, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FleetFilter } from '../../../../Fleet/Filter';
import { EmployeeFilter } from '../../../../Employee/Filter';
import { ClientFilter } from '../../../../Client/Filter';
const { Panel } = Collapse;

export function AccordeonFilters({ filterProps }) {
	const { t } = useTranslation();

	const handleInputChange = (e) => {
		filterProps.setMasterAirWayBill(e.target.value);
	};

	return (
		<Collapse
			defaultActiveKey={[]}
			style={{
				backgroundColor: '#fff',
				borderRadius: '8px',
				marginBottom: '1rem',
				width: '100%',
				margin: '0 auto',
			}}
		>
			<Panel header={t('filters.filters')} key="1">
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<p style={{ fontSize: '13px', margin: '5px 0' }}>
							{t('manifests.masterAirWayBill.text')}
						</p>
						<Input
							placeholder={t('manifests.masterAirWayBill.text')}
							value={filterProps.masterAirWayBill}
							onChange={handleInputChange}
						/>
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '13px', margin: '5px 0' }}>
							{t('clients.name')}
						</p>
						<ClientFilter {...filterProps} />
					</Col>
				</Row>
				<Row justify="end" align="middle" style={{ marginTop: '20px' }}>
					<Col>
						<Button type="primary" onClick={filterProps.handleSearch}>
							{t('filters.search')}
						</Button>
					</Col>
				</Row>
			</Panel>
		</Collapse>
	);
}
