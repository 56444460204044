import { Button, Card, Drawer, Layout } from 'antd';
import { useEffect, useState } from 'react';
import { MainMenu } from '../../Dashboard/MainMenu';
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { LenguajeSelect } from '../../navAcordion';
import { useTranslation } from 'react-i18next';
import {
	getFromLocalStorage,
	saveToLocalStorage,
} from '../../Dashboard/Login/storage';
import { useUser } from '../../Context/User';
import { useNavigate } from 'react-router-dom';

const { Header, Content, Footer } = Layout;

const MainLayout = ({ children }) => {
	const { logout, user } = useUser();
	// const location = useLocation();
	const { t } = useTranslation();
	const [selectedMenu, setSelectedMenu] =
		useState(getFromLocalStorage('title')) || '';

	const navigate = useNavigate();

	useEffect(() => {
		if (!user) {
			navigate('/login');
		}
	}, [user]);

	const [visible, setVisible] = useState(false);

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	return (
		<Layout className="flex w-full h-full fixed">
			<Header className="flex w-full justify-between items-center bg-white p-4 shadow-md">
				<div className="text-xl font-bold">Movers</div>
				<div className="flex items-center">
					<LenguajeSelect />
					<Button type="primary" icon={<MenuOutlined />} onClick={showDrawer} />
					<Button
						type="primary"
						icon={<LogoutOutlined />}
						onClick={logout}
						className="ml-2"
					/>
				</div>
				<Drawer
					title="Menu"
					placement="left"
					closable={true}
					onClose={onClose}
					open={visible}
				>
					<MainMenu
						selectedMenu={selectedMenu}
						setSelectedMenu={setSelectedMenu}
						setVisible={setVisible}
					/>
				</Drawer>
			</Header>
			<h1 className="text-2xl font-bold text-center">
				{selectedMenu && t('control.' + selectedMenu)}
			</h1>
			<Content className="flex-grow justify-center p-4 overflow-auto">
				<Card className="flex-grow flex-col items-center justify-center p-4 overflow-auto">
					{children}
				</Card>
				<div></div>
			</Content>
			<Footer className="text-center bg-gray-100 p-4">
				Movers Maegmant ©2024 Created by CTIT DEVELOPMENT
			</Footer>
		</Layout>
	);
};

export default MainLayout;
